import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { FC, useMemo } from 'react';

const ProductsDevPage: FC<PageProps> = () => {
  const {
    allProduct: { edges: products },
  } = useStaticQuery(graphql`
    query {
      allProduct {
        edges {
          node {
            code
            specifications {
              name
              unit
              value
            }
            originalFile
          }
        }
      }
    }
  `);

  const q = useMemo(
    () =>
      products
        .flatMap(({ node: prod }) =>
          prod.specifications.map((spec) => ({
            ...spec,
            code: prod.code,
            file: prod.originalFile,
          }))
        )
        .reduce((acc, it) => {
          const val = { specValue: it.value, file: it.file, code: it.code };
          let spec = acc.find((s) => s.name === it.name && s.unit === it.unit);
          if (!spec) {
            spec = {
              name: it.name,
              unit: it.unit,
              values: [val],
            };
            return [...acc, spec];
          }
          spec.values = [...spec.values, val];
          return acc;
        }, [])
        .map((spec) => ({
          ...spec,
          values: spec.values
            .reduce((acc, it) => {
              let val = acc.find((s) => s.specValue === it.specValue);
              if (!val) {
                val = {
                  specValue: it.specValue,
                  files: [it.file],
                  codes: [it.code],
                };
                return [...acc, val];
              }
              val.files = [...val.files, it.file];
              val.codes = [...val.codes, it.code];
              return acc;
            }, [])
            .map((it) => ({
              ...it,
              files: Array.from(new Set(it.files)),
              codes: Array.from(new Set(it.codes)),
            }))
            .filter((it) => !!it.specValue)
            .sort((a: any, b: any) => a.specValue.localeCompare(b.specValue)),
        }))
        .map(({ values, ...spec }) => ({
          ...spec,
          detectedType: values
            .map((it) => it.specValue)
            .some((val) => !/^-?[\d,.]+$/.test(val))
            ? 'text'
            : 'number',
          quickValues: values.map((it) => it.specValue),
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [products]
  );

  // console.log(
  //   Array.from(new Set(q.map((it) => it.name as string))).sort(
  //     (a: string, b: string) => a.localeCompare(b)
  //   )
  // );

  const prodCodes = useMemo(
    () => products.map(({ node }) => node.code.replace(/\//g, '_')).join('\n'),
    [products]
  );

  // console.log(prodCodes);

  return (
    <Layout className="relative" hideHeader minimalFooter>
      <SEO url="products-dev" title="Products dev" description="" />
      <section className="min-h-screen flex flex-col">
        <div className="flex-1 flex flex-col p-4 pb-6 whitespace-pre-wrap">
          <p>{q.length} specs</p>
          <p>{prodCodes.length} specs</p>
          {/*<p>{JSON.stringify(q, null, 2)}</p>*/}
        </div>
      </section>
    </Layout>
  );
};

export default ProductsDevPage;
